/* ====  Preloader styles ==== */

#hola {
    width: 100vw;
    height: 100vh;
    background-color: #333;
    position: fixed;
    z-index: 999;
}

#preloader {
    position: relative;
    width: 80px;
    height: 80px;
    top: 45%;
    margin: 0 auto;
}

#preloader span {
    position: absolute;
    border: 8px solid #ffe066;
    border-top: 8px solid transparent;
    border-radius: 999px;
}

#preloader span:nth-child(1) {
    width: 80px;
    height: 80px;
    animation: spin-1 2s infinite linear;
}

#preloader span:nth-child(2) {
    top: 20px;
    left: 20px;
    width: 40px;
    height: 40px;
    animation: spin-2 1s infinite linear;
}

@keyframes spin-1 {
    0% {
        transform: rotate(360deg);
        opacity: 1;
    }
    50% {
        transform: rotate(180deg);
        opacity: 0.5;
    }
    100% {
        transform: rotate(0deg);
        opacity: 1;
    }
}

@keyframes spin-2 {
    0% {
        transform: rotate(0deg);
        opacity: 0.5;
    }
    50% {
        transform: rotate(180deg);
        opacity: 1;
    }
    100% {
        transform: rotate(360deg);
        opacity: 0.5;
    }
}


/* ====  Page layout ==== */

.home {
    min-height: 100vh;
    position: relative;
}

.home:before {
    position: absolute;
    width: 50%;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 0;
    content: '';
    background-color: #3c3c3c;
}

.home:after {
    position: absolute;
    width: 50%;
    height: 100%;
    z-index: 10;
    top: 0;
    right: 0;
    content: '';
    background-color: #3c3c3c;
}

.animate-border {
    border: 40px solid #F5F5F5;
    transition: border .5s .2s ease;
}

.home.divide:before {
    transition: all .6s 1.2s cubic-bezier(0.645, 0.045, 0.355, 1.000);
    width: 0;
}

.home.divide:after {
    transition: all .6s 1.2s cubic-bezier(0.645, 0.045, 0.355, 1.000);
    width: 0;
}

@for $i from 1 through 6 {
    @keyframes preload-show-#{$i} {
        from {
            transform: rotateZ(60* $i + deg) rotateY(-90deg) rotateX(0deg);
            border-left-color: #9c2f2f;
        }
    }
    @keyframes preload-hide-#{$i} {
        to {
            transform: rotateZ(60* $i + deg) rotateY(-90deg) rotateX(0deg);
            border-left-color: #9c2f2f;
        }
    }
    @keyframes preload-cycle-#{$i} {
        $startIndex: $i*5;
        $reverseIndex: (80 - $i*5);
        #{$startIndex * 1%} {
            transform: rotateZ(60* $i + deg) rotateY(90deg) rotateX(0deg);
            border-left-color: #9c2f2f;
        }
        #{$startIndex + 5%},
        #{$reverseIndex * 1%} {
            transform: rotateZ(60* $i + deg) rotateY(0) rotateX(0deg);
            border-left-color: #f7484e;
        }
        #{$reverseIndex + 5%},
        100% {
            transform: rotateZ(60* $i + deg) rotateY(90deg) rotateX(0deg);
            border-left-color: #9c2f2f;
        }
    }
}

@keyframes preload-flip {
    0% {
        transform: rotateY(0deg) rotateZ(-60deg);
    }
    100% {
        transform: rotateY(360deg) rotateZ(-60deg);
    }
}

.preloader {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 20px;
    display: block;
    width: 3.75em;
    height: 4.25em;
    margin-left: -1.875em;
    margin-top: -2.125em;
    transform-origin: center center;
    transform: rotateY(180deg) rotateZ(-60deg);
    .slice {
        border-top: 1.125em solid transparent;
        border-right: none;
        border-bottom: 1em solid transparent;
        border-left: 1.875em solid #f7484e;
        position: absolute;
        top: 0px;
        left: 50%;
        transform-origin: left bottom;
        border-radius: 3px 3px 0 0;
    }
    @for $i from 1 through 6 {
        .slice:nth-child(#{$i}) {
            transform: rotateZ(60* $i + deg) rotateY(0deg) rotateX(0);
            animation: .15s linear .9 - $i*.08s preload-hide-#{$i} both 1;
        }
    }
    &.loading {
        animation: 2s preload-flip steps(2) infinite both;
        @for $i from 1 through 6 {
            .slice:nth-child(#{$i}) {
                transform: rotateZ(60* $i + deg) rotateY(90deg) rotateX(0);
                animation: 2s preload-cycle-#{$i} linear infinite both;
            }
        }
    }
}