html {
  padding: 0;
  margin: 0;
}

body {

  padding: 0;
  margin: 0;
  font-family: $font2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  line-height: 26px;
}

h1{
  font-family: $font1;
  font-weight: 800;
}

a:hover,
a:focus,
a:active,
button:hover,
button:focus,
button:active,
input:hover,
input:focus,
input:active,
textarea:hover,
textarea:focus,
textarea:active {
  text-decoration: none;
  outline: 0;
}

:focus,
button:focus,
.btn:hover,
.btn:focus,
.btn.focus {
  outline: 0;
}

a:hover {
  @include my_transition(.4s);
}

textarea {
  resize: none;
}

::-moz-selection { /* Code for Firefox */
  color: #fff !important;
  background: $primary-clr !important;
}

::selection {
  color: #fff !important;
  background: $primary-clr !important;
}

/*:::::clearfix class:::::*/
.group {
  margin: 0 auto;
}

.group:after {
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
  content: ".";
}

* html .group { /* IE6 */
  height: 1%;
}

*:first-child + html .group { /* IE7 */
  min-height: 1px;
}
