.ov-menu {
    --color-text: #802AF5;
    --color-bg: #333;
    --color-link: #4d4f5f;
    --color-link-hover: #bfb5b5;
    --color-info: #fff;
    --color-main-bg: #24262d;
    --path-fill-1: #000000;
    --path-fill-2: #1d1d1f;
    --path-fill-3: #E7C3F4;
    --color-title: #fff;
    --font-family-title: 'Arapey', serif;
    --font-size-title: 5vmax;
    --font-weight-title: 400;
    --color-menu: #000000;
    --color-menu-hover: #fff;
    --font-family-menu: 'Arapey', serif;
    --font-size-menu: 2vmax;
    --font-weight-menu: 400;
    --button-bg: #fff;
    --button-circle: #ada4a4;
    --button-line: #222;
}


/* Fade effect */

.js body {
    opacity: 0;
    transition: opacity 0.3s;
}

.js body.render {
    opacity: 1;
}

.hidden {
    position: absolute;
    overflow: hidden;
    width: 0;
    height: 0;
    pointer-events: none;
}

.global-menu {
    width: 100vw;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 100;
    text-align: center
}

.global-menu__item {
    color: var(--color-menu);
    font-size: 22px;
    font-weight: bold;
    opacity: 0;
    transform: translateY(-100%);
    pointer-events: none;
    display: block;
    margin: 30px 0;
    transition: transform 0.3s, opacity 0.3s;
    transition-timing-function: ease-in;
    text-decoration: none;
    text-transform: uppercase;
}

.global-menu__item {
    transition: transform 0.1s, opacity 0.1s;
    transform: translateY(100%);
    transition-timing-function: ease-out;
}

.global-menu__item:hover {
    color: var(--color-text);
}

.global-menu__item.is-opened {
    opacity: 1;
    transform: translateY(0) rotate(0);
    pointer-events: auto;
    transition-timing-function: ease;
}

.global-menu__item:nth-of-type(1) {
    transition-delay: 0s;
}

.global-menu__item.is-opened:nth-of-type(1) {
    transition-delay: 0.85s;
}

.global-menu__item:nth-of-type(2) {
    transition-delay: 0.05s;
}

.global-menu__item.is-opened:nth-of-type(2) {
    transition-delay: 0.8s;
}

.global-menu__item:nth-of-type(3) {
    transition-delay: 0.1s;
}

.global-menu__item.is-opened:nth-of-type(3) {
    transition-delay: 0.75s;
}

.global-menu__item:nth-of-type(4) {
    transition-delay: 0.15s;
}

.global-menu__item.is-opened:nth-of-type(4) {
    transition-delay: 0.7s;
}


/* demo 3 */

.global-menu__item.is-opened {
    transition-duration: 0.3s;
}

.global-menu__item:nth-of-type(1) {
    transition-delay: 0s;
}

.global-menu__item.is-opened:nth-of-type(1) {
    transition-delay: 0.65s;
}

.global-menu__item:nth-of-type(2) {
    transition-delay: 0s;
}

.global-menu__item.is-opened:nth-of-type(2) {
    transition-delay: 0.7s;
}

.global-menu__item:nth-of-type(3) {
    transition-delay: 0s;
}

.global-menu__item.is-opened:nth-of-type(3) {
    transition-delay: 0.75s;
}

.global-menu__item:nth-of-type(4) {
    transition-delay: 0s;
}

.global-menu__item.is-opened:nth-of-type(4) {
    transition-delay: 0.8s;
}

.shape-overlays {
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
}

.shape-overlays.is-opened {
    pointer-events: auto;
}

.shape-overlays__path:nth-of-type(1) {
    fill: var(--path-fill-1);
}

.shape-overlays__path:nth-of-type(2) {
    fill: var(--path-fill-2);
}

.shape-overlays__path:nth-of-type(3) {
    fill: var(--path-fill-3);
}

.shape-overlays__path:nth-of-type(4) {
    fill: var(--path-fill-4);
}

@-webkit-keyframes intervalHamburgerBorder {
    0% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    80% {
        -webkit-transform: scale(1.6);
        transform: scale(1.6);
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(1.6);
        transform: scale(1.6);
    }
}

@keyframes intervalHamburgerBorder {
    0% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    80% {
        -webkit-transform: scale(1.6);
        transform: scale(1.6);
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(1.6);
        transform: scale(1.6);
    }
}

.hamburger {
    width: 64px;
    height: 64px;
    display: block;
    cursor: pointer;
    position: fixed;
    top: 2.25em;
    right: 2.25em;
    z-index: 110;
    border-radius: 50%;
    background-color: var(--button-bg);
    pointer-events: auto;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    right: 30px;
    top: 30px;
    z-index: 10;
    -webkit-box-shadow: 1px 1px 12px 3px rgba(0, 0, 0, .08);
    box-shadow: 1px 1px 12px 3px rgba(0, 0, 0, .08);
}

.hamburger__line {
    width: 28px;
    height: 2px;
    overflow: hidden;
    position: absolute;
    z-index: 10;
}

.hamburger__line-in {
    width: 84px;
    height: 2px;
    position: absolute;
    top: 0;
    left: 0;
}

.hamburger__line-in::before,
.hamburger__line-in::after {
    width: 28px;
    height: 2px;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    background-color: var(--button-line);
}

.hamburger__line-in::before {
    left: -56px;
}

.hamburger__line-in::after {
    left: 0;
}

.hamburger__line--01,
.hamburger__line--02,
.hamburger__line--03,
.hamburger__line--cross01,
.hamburger__line--cross02 {
    left: 18px;
}

.hamburger__line--01 {
    top: 24.6px;
}

.hamburger__line--02,
.hamburger__line--cross01,
.hamburger__line--cross02 {
    top: 31px;
}

.hamburger__line--03 {
    top: 37.4px;
}

.hamburger__line--cross01 {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.hamburger__line--cross02 {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.hamburger__line {
    -webkit-transition-duration: 0.6s;
    transition-duration: 0.6s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger__line-in {
    -webkit-transition-duration: 0.6s;
    transition-duration: 0.6s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger__line-in::before,
.hamburger__line-in::after {
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
}

.hamburger__line-in--cross01,
.hamburger__line-in--cross02 {
    -webkit-transform: translateX(-33.3%);
    transform: translateX(-33.3%);
}

.hamburger__line-in--01 {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
}

.hamburger__line-in--02 {
    -webkit-transition-delay: 0.25s;
    transition-delay: 0.25s;
}

.hamburger__line-in--02::before,
.hamburger__line-in--02::after {
    -webkit-transition-delay: 0.05s;
    transition-delay: 0.05s;
}

.hamburger__line-in--03 {
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
}

.hamburger__line-in--03::before,
.hamburger__line-in--03::after {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
}

.hamburger__line-in--cross01 {
    -webkit-transition-delay: 0.0s;
    transition-delay: 0.0s;
}

.hamburger__line-in--cross02 {
    -webkit-transition-delay: 0.05s;
    transition-delay: 0.05s;
}

.hamburger__line-in--cross02::before,
.hamburger__line-in--cross02::after {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
}

.hamburger.is-opened-navi .hamburger__line-in--01,
.hamburger.is-opened-navi .hamburger__line-in--02,
.hamburger.is-opened-navi .hamburger__line-in--03 {
    -webkit-transform: translateX(33.3%);
    transform: translateX(33.3%);
}

.hamburger.is-opened-navi .hamburger__line-in--cross01,
.hamburger.is-opened-navi .hamburger__line-in--cross02 {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.hamburger.is-opened-navi .hamburger__line-in--01 {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

.hamburger.is-opened-navi .hamburger__line-in--02 {
    -webkit-transition-delay: 0.05s;
    transition-delay: 0.05s;
}

.hamburger.is-opened-navi .hamburger__line-in--03 {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
}

.hamburger.is-opened-navi .hamburger__line-in--cross01 {
    -webkit-transition-delay: 0.25s;
    transition-delay: 0.25s;
}

.hamburger.is-opened-navi .hamburger__line-in--cross02 {
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
}

.hamburger:hover .hamburger__line-in::before,
.hamburger:hover .hamburger__line-in::after {
    -webkit-transform: translateX(200%);
    transform: translateX(200%);
}

.hamburger:hover .hamburger__line-in--01::before,
.hamburger:hover .hamburger__line-in--01::after,
.hamburger:hover .hamburger__line-in--02::before,
.hamburger:hover .hamburger__line-in--02::after,
.hamburger:hover .hamburger__line-in--03::before,
.hamburger:hover .hamburger__line-in--03::after {
    -webkit-transition-duration: 1s;
    transition-duration: 1s;
}

.hamburger:hover .hamburger__line-in--cross01::before,
.hamburger:hover .hamburger__line-in--cross01::after,
.hamburger:hover .hamburger__line-in--cross02::before,
.hamburger:hover .hamburger__line-in--cross02::after {
    -webkit-transition-duration: 0s;
    transition-duration: 0s;
}

.hamburger.is-opened-navi:hover .hamburger__line-in--cross01::before,
.hamburger.is-opened-navi:hover .hamburger__line-in--cross01::after,
.hamburger.is-opened-navi:hover .hamburger__line-in--cross02::before,
.hamburger.is-opened-navi:hover .hamburger__line-in--cross02::after {
    -webkit-transition-duration: 1s;
    transition-duration: 1s;
}

.hamburger.is-opened-navi:hover .hamburger__line-in--01::before,
.hamburger.is-opened-navi:hover .hamburger__line-in--01::after,
.hamburger.is-opened-navi:hover .hamburger__line-in--02::before,
.hamburger.is-opened-navi:hover .hamburger__line-in--02::after,
.hamburger.is-opened-navi:hover .hamburger__line-in--03::before,
.hamburger.is-opened-navi:hover .hamburger__line-in--03::after {
    -webkit-transition-duration: 0s;
    transition-duration: 0s;
}