$url1: url("../img/spritsheet.png");
@font-face {
    font-family: 'omegle';
    src: url('../fonts/OMEGLE.ttf') format('ttf'),
}

//:::::::::::Variables:::::::::::::
$primary-clr: #8d3b96;
$prime-dark:#7b1f85;
$font1: 'Montserrat',
sans-serif;
$font2: 'Manrope',
sans-serif;
$font3: 'omegle';
//:::::::::::Variables:::::::::::::
.bg-theme-clr {
    background-color: $primary-clr;
}

$mountain_meadow: #21b387;
$red_stage: #c54c06;
$boston_blue: #3186b7;
$vivid_violet: #8c3ca9;
$persian_red: #d2393b;
$colors: (mountain_meadow,
$mountain_meadow),
(red_stage,
$red_stage),
(boston_blue,
$boston_blue),
(vivid_violet,
$vivid_violet),
(persian_red,
$persian_red);
@each $thcolors,
$color in $colors {
    .txtcolor_#{$thcolors} {
        color: $color;
    }
    .bgcolor_#{$thcolors} {
        background-color: $color;
    }
}