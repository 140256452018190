 /*
[Master Stylesheet]
Project Name    : BS5 Template
Team & Company  : Deepsign.in
Desgined By     : Deepu [www.deepus.in]
Developed By    : Deepu
*/
 
 @import 'node_modules/bootstrap/scss/bootstrap';
 @import 'helpers';
 @import 'theme_variable';
 @import 'loader';
 @import 'base';
 @import 'animate';
 @import 'menu';
 h1 {
     padding: 60px 0;
     text-shadow: 2px 2px #a9a9a9;
     font-weight: 800;
 }
 
 .btn-primary {
     background-color: $primary-clr;
     border-color: $primary-clr;
     &:hover {
         background-color: $prime-dark;
         border-color: $prime-dark;
     }
 }
 
 .home-hero {
     min-height: 100vh;
     display: flex;
     justify-content: center;
     align-items: center;
     background-color: #F5F5F5;
 }
 
 .pic_mask {
     position: relative;
     z-index: 1;
     transition: 0.4s cubic-bezier(1, -1, 0, 2);
     img {
         max-width: 284px;
         clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
         animation: circle 4s alternate infinite;
     }
 }
 
 @keyframes circle {
     0% {
         clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
     }
     100% {
         clip-path: polygon(100% 22%, 100% 78%, 46% 100%, 0 52%, 44% 0);
     }
 }
 
 .animated_wrap {
     position: relative;
     margin-top: -100px;
     overflow: hidden;
 }
 
 .white_arrw {
     width: 0px;
     height: 0px;
     fill: #ffffff;
     position: relative;
     z-index: 3;
     margin: 0 auto;
     transition: all .6s 1.2s cubic-bezier(0.645, 0.045, 0.355, 1.000);
     &.animated {
         width: 60px;
         height: 60px;
         -moz-transform: rotate(180deg);
         -webkit-transform: rotate(180deg);
         -o-transform: rotate(180deg);
         -ms-transform: rotate(180deg);
         transform: rotate(180deg);
         transition: all .3s .6s cubic-bezier(0.645, 0.045, 0.355, 1.000);
     }
 }
 
 .down_arrow_wrap {
     position: relative;
     z-index: 1;
     background-color: #F5F5F5;
     padding-bottom: 30px;
 }
 
 .down_arrow_wrap span {
     width: 60px;
     height: 60px;
     display: inline-block;
     line-height: 60px;
     border-radius: 50%;
     z-index: 2;
 }
 
 .ani_circle {
     z-index: 1;
     position: absolute;
     top: 20px;
     left: 50%;
     margin-left: -5px;
 }
 
 .briefing {
     position: relative;
     z-index: 2;
     color: #fff;
 }
 
 .circle {
     position: fixed;
     top: 0;
     left: 0;
     z-index: 0;
 }
 
 .morphext>.animated {
     display: inline-block;
 }
 
 .circles {
     position: relative;
     span {
         width: 100px;
         height: 100px;
         display: block;
         border-radius: 50%;
         &.c1 {
             position: absolute;
             left: 40px;
             top: -180px;
             background: #E7C3F4;
         }
         &.c2 {
             position: absolute;
             right: 70px;
             top: -110px;
             background: #C3D0F4;
         }
     }
 }
 
 .abt_img_wrap {
     img {
         margin: 20px auto;
     }
 }
 
 @include media-breakpoint-up(md) {
     .abt_img_wrap {
         position: relative;
         img {
             position: relative;
             max-width: 90%;
             bottom: 300px;
             z-index: 4;
             -webkit-box-shadow: 8px 6px 18px 7px rgba(0, 0, 0, .3);
             box-shadow: 8px 6px 18px 7px rgba(0, 0, 0, .3);
         }
     }
     .abt_info {
         font-size: 22px;
     }
 }
 
 .abt_info {
     line-height: 30px;
     font-size: 18px;
     font-family: $font1;
     font-weight: 800;
     margin-bottom: 80px !important;
 }
 
 .bighead {
     font-size: 120px;
     text-transform: uppercase;
     font-family: $font1;
     font-weight: 800;
     -webkit-text-stroke-width: 1px;
     -webkit-text-stroke-color: #808080;
     -webkit-text-fill-color: transparent;
 }
 
 @include media-breakpoint-down(md) {
     .bighead {
         font-size: 70px;
     }
 }
 
 @include media-breakpoint-down(md) {
     .abt_info {
         text-align: center;
     }
 }
 
 .gallery_wrap {
     overflow: hidden;
     .gallery_thumb {
         margin: 0;
         padding: 0;
         width: max-content;
         li {
             display: inline-block;
             margin: 15px;
             position: relative;
             &:after {
                 content: "";
                 position: absolute;
                 top: 0;
                 right: 0;
                 bottom: 0;
                 left: 0;
                 @include dot-bg( rgba(0, 0, 0, 0.74), rgba(255, 255, 255, 0), 10%, 5px);
                 opacity: 0.2;
             }
             p {
                 position: absolute;
                 width: 100%;
                 text-align: center;
                 text-transform: uppercase;
                 color: #fff;
                 font-weight: 800;
                 font-size: 24px;
                 bottom: 16px;
                 z-index: 2;
             }
         }
         &.gallerys2 {
             float: right;
         }
     }
 }
 
 .dot_bg {
     height: 160px;
     width: 90%;
     margin-top: 20px;
     @include dot-bg( black, white, 20%, 30px);
 }
 
 footer {
     width: 100%;
     bottom: 0;
     left: 0;
     display: block;
     padding-bottom: 3rem;
     overflow: hidden;
     height: 400px;
     bottom: 0;
     filter: url("#goo");
     position: relative;
     svg {
         width: 100%;
     }
     .foot_cnt {
         position: relative;
         z-index: 3;
         bottom: -320px;
         color: #fff;
         p {
             text-align: right;
         }
     }
     .footer-link {
         margin: 0;
         padding: 0;
         text-align: left;
         li {
             list-style-type: none;
             display: inline-block;
             margin-right: 10px;
             a {
                 color: #fff;
                 text-decoration: none;
             }
         }
     }
 }
 
 @include media-breakpoint-down(md) {
     .foot_cnt p,
     .footer-link {
         text-align: center !important;
     }
 }
 
 footer::after {
     content: "";
     position: absolute;
     bottom: 0;
     left: 0;
     width: 100%;
     height: 190px;
     clip-path: url("#wave");
     background-color: #28323B;
 }
 
 @media only screen and (max-width: 700px) {
     footer::after {
         clip-path: none;
         height: 150px;
     }
 }
 
 footer .ball {
     width: 100px;
     height: 100px;
     border-radius: 50%;
     background-color: #28323B;
     position: absolute;
     animation: up 5s cubic-bezier(0.18, 0.89, 0.32, 1.28) infinite;
 }
 
 @media only screen and (max-width: 700px) {
     footer .ball {
         animation: up-small 5s cubic-bezier(0.18, 0.89, 0.32, 1.28) infinite;
     }
 }
 
 @keyframes up {
     0% {
         bottom: 0px;
         width: 100px;
         height: 100px;
     }
     50% {
         bottom: 200px;
     }
     100% {
         bottom: 0px;
         width: 0px;
         height: 0px;
     }
 }
 
 @keyframes up-small {
     0% {
         bottom: 0px;
         width: 100px;
         height: 100px;
     }
     50% {
         bottom: 150px;
     }
     100% {
         bottom: 0px;
         width: 0px;
         height: 0px;
     }
 }
 
 .about_hero {
     min-height: 100vh;
 }
 
 .abt_downarw_wrp {
     max-width: 40px;
     margin: 0 auto;
     margin-top: 40px;
     display: block;
     img {
         max-width: 40px;
     }
 }
 
 .panel {
     width: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
     text-align: center;
     position: relative;
     box-sizing: border-box;
     &.description {
         height: 100vh;
     }
 }
 /* ---- particles.js container ---- */
 
 #particles-js {
     position: absolute;
     width: 100%;
     height: 100%;
     background-repeat: no-repeat;
     background-size: 20%;
     background-position: 50% 50%;
     background-color: #F5F5F5;
     canvas {
         display: block;
         vertical-align: bottom;
     }
 }
 /* ---- stats.js ---- */
 
 .profimgwrap {
     max-width: 200px;
     position: relative;
     margin: 0 auto;
     img {
         border-radius: 50%;
     }
 }
 
 .about-hero {
     position: relative;
     z-index: 4;
     .name_profile {
         font-family: $font1;
         font-weight: 800;
         margin: 50px auto;
         letter-spacing: 5px;
     }
 }
 
 .profile-content {
     background-color: #fff;
 }
 
 .timeline {
     margin: 50px 0;
     padding: 0;
     .time-single {
         padding: 25px 0;
         background-color: #fff;
         position: relative;
         padding-top: 0;
         margin-top: 40px;
         p {
             margin: 0;
             padding: 0;
         }
         border-bottom: 1px solid #d5d5d5;
         .line {
             width: 1px;
             height: 0px;
             position: absolute;
             bottom: -40px;
             left: 50%;
             background-color: #d5d5d5;
         }
     }
     .year {
         width: 100px;
         height: 100px;
         background-color: whitesmoke;
         line-height: 100px;
         font-weight: bold;
         text-align: center;
         font-size: 20px;
         border-radius: 50%;
         margin: 15px auto !important;
     }
     .company-name {
         font-weight: bold;
     }
     .position {
         font-size: 25px;
         font-weight: 800;
         color: #8c8a8a;
     }
     .duration {
         font-style: italic;
         font-weight: 400;
         margin-bottom: 30px !important;
     }
 }
 
 .works_hero,
 .contact_hero {
     padding: 30px;
     position: relative;
     .bighead {
         text-align: center;
         margin: 100px auto !important;
     }
     &:before {
         height: 160px;
         width: 40%;
         margin-top: 20px;
         @include dot-bg( #E7C3F4,
         white,
         20%,
         30px);
         content: "";
         position: absolute;
         left: 0;
         top: 270px;
         z-index: -1;
     }
 }
 /* ---- grid ---- */
 
 .grid:after {
     content: '';
     display: block;
     clear: both;
 }
 /* ---- .grid-item ---- */
 
 .grid-sizer,
 .grid-item {
     width: 23%;
     margin: 10px;
     border: 6px solid #fff;
     -webkit-box-shadow: 8px 6px 18px 7px rgba(0, 0, 0, .1);
     box-shadow: 8px 6px 18px 7px rgba(0, 0, 0, .1);
     .thumbnail {
         position: relative;
         &:after {
             content: "";
             position: absolute;
             top: 0;
             right: 0;
             bottom: 0;
             left: 0;
             @include dot-bg( rgba(0,
             0,
             0,
             0.74),
             rgba(255,
             255,
             255,
             0),
             10%,
             5px);
             opacity: 0.2;
         }
     }
     .work-info {
         position: absolute;
         left: 0;
         bottom: 0;
         right: 0;
         padding: 16px;
         background: rgba(33, 32, 32, .8);
         color: #fff;
         font-weight: bold;
     }
 }
 
 @include media-breakpoint-down(md) {
     .grid-sizer,
     .grid-item {
         width: 97%;
     }
 }
 
 @include media-breakpoint-between(md,
 xl) {
     .grid-sizer,
     .grid-item {
         width: 30%;
     }
 }
 
 .grid-sizer {
     opacity: 0;
 }
 
 .filter-button-group {
     text-align: right;
     margin: 20px;
     button {
         border: 0;
         padding: 10px;
         margin: 10px;
         font-size: 15px;
         background-color: #fff;
         text-transform: uppercase;
         &.is-checked {
             border-bottom: 1px solid #000;
         }
     }
 }
 
 .cpge_email {
     color: #333;
     text-align: center;
     span {
         background-color: #fff;
     }
     a {
         color: $primary-clr;
         font-weight: bold;
         text-decoration: none;
     }
 }
 
 .social-connect {
     margin: 0;
     padding: 0;
     margin-bottom: 50px;
     li {
         margin: 30px 10px;
         list-style-type: none;
         display: inline-block;
         img {
             max-width: 40px;
         }
     }
 }
 
 .connect-head {
     font-weight: 800;
     font-size: 22px;
     font-family: $font1;
     margin: 40px auto;
     margin-top: 100px;
     text-transform: uppercase;
     color: #333;
 }
 
 .button {
     pointer-events: auto;
     cursor: pointer;
     background: #e7e7e7;
     border: none;
     padding: 1.5rem 3rem;
     margin: 0;
     position: relative;
     display: inline-block;
     margin-top: 60px;
     text-decoration: none;
     &:hover {
         color: #fff
     }
 }
 
 @include media-breakpoint-up(lg) {
     .button {}
     margin-top: 150px;
 }
 
 .button::before,
 .button::after {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
 }
 
 .button--janus {
     font-weight: 900;
     width: 175px;
     height: 120px;
     color: #fff;
     background: none;
 }
 
 .button--janus::before {
     content: '';
     background: #e6e6e6;
     -webkit-clip-path: path("M154.5,88.5 C131,113.5 62.5,110 30,89.5 C-2.5,69 -3.5,42 4.5,25.5 C12.5,9 33.5,-6 85,3.5 C136.5,13 178,63.5 154.5,88.5 Z");
     clip-path: path("M154.5,88.5 C131,113.5 62.5,110 30,89.5 C-2.5,69 -3.5,42 4.5,25.5 C12.5,9 33.5,-6 85,3.5 C136.5,13 178,63.5 154.5,88.5 Z");
     transition: clip-path 0.5s cubic-bezier(0.585, 2.5, 0.645, 0.55), -webkit-clip-path 0.5s cubic-bezier(0.585, 2.5, 0.645, 0.55), background 0.5s ease;
 }
 
 .button--janus:hover::before {
     background: #000;
     -webkit-clip-path: path("M143,77 C117,96 74,100.5 45.5,91.5 C17,82.5 -10.5,57 5.5,31.5 C21.5,6 79,-5.5 130.5,4 C182,13.5 169,58 143,77 Z");
     clip-path: path("M143,77 C117,96 74,100.5 45.5,91.5 C17,82.5 -10.5,57 5.5,31.5 C21.5,6 79,-5.5 130.5,4 C182,13.5 169,58 143,77 Z");
 }
 
 .button--janus::after {
     content: '';
     height: 86%;
     width: 97%;
     top: 5%;
     border-radius: 58% 42% 55% 45% / 56% 45% 55% 44%;
     border: 1px solid #000;
     transform: rotate(-20deg);
     z-index: -1;
     transition: transform 0.5s cubic-bezier(0.585, 2.5, 0.645, 0.55);
 }
 
 .button--janus:hover::after {
     transform: translate3d(0, -5px, 0);
 }
 
 .button--janus span {
     display: block;
     transition: transform 0.3s ease;
     mix-blend-mode: difference;
 }
 
 .button--janus:hover span {
     transform: translate3d(0, -10px, 0);
 }
 
 #scroll {
     position: fixed;
     right: 20px;
     bottom: 20px;
     cursor: pointer;
     width: 50px;
     height: 50px;
     background-color: $primary-clr;
     text-indent: -9999px;
     display: none;
     -webkit-border-radius: 60px;
     -moz-border-radius: 60px;
     border-radius: 60px;
     z-index: 30;
 }
 
 #scroll span {
     position: absolute;
     top: 50%;
     left: 50%;
     margin-left: -8px;
     margin-top: -12px;
     height: 0;
     width: 0;
     border: 8px solid transparent;
     border-bottom-color: #ffffff;
 }
 
 #scroll:hover {
     background-color: $primary-clr;
     opacity: 1;
     filter: "alpha(opacity=100)";
     -ms-filter: "alpha(opacity=100)";
 }
 
 .download-resume {
     svg {
         color: #fff;
         max-width: 16px;
         display: inline-block;
     }
 }
 /* Naira */
 
 .button--naira {
     padding: 0;
     overflow: hidden;
     -webkit-transition: background-color 0.3s;
     transition: background-color 0.3s;
 }
 
 .button--naira::before {
     content: '';
     position: absolute;
     left: -50%;
     width: 200%;
     height: 200%;
     background: #37474f;
     top: -50%;
     z-index: -1;
     -webkit-transform: translate3d(0, -100%, 0) rotate3d(0, 0, 1, -10deg);
     transform: translate3d(0, -100%, 0) rotate3d(0, 0, 1, -10deg);
 }
 
 .button--naira.button--inverted::before {
     background: #7986cb;
 }
 
 .button--naira-up::before {
     -webkit-transform: translate3d(0, 100%, 0) rotate3d(0, 0, 1, 10deg);
     transform: translate3d(0, 100%, 0) rotate3d(0, 0, 1, 10deg);
 }
 
 .button--naira>span {
     display: block;
 }
 
 .button--naira .button__icon {
     position: absolute;
     top: 0;
     width: 100%;
     left: 0;
     color: #fff;
     -webkit-transform: translate3d(0, -100%, 0);
     transform: translate3d(0, -100%, 0);
 }
 
 .button--naira-up .button__icon {
     -webkit-transform: translate3d(0, 100%, 0);
     transform: translate3d(0, 100%, 0);
 }
 
 .button--naira>span,
 .button--naira .button__icon {
     padding: 1em 2em;
     -webkit-transition: -webkit-transform 0.3s;
     transition: transform 0.3s;
     -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
     transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
 }
 
 .button--naira:hover::before {
     -webkit-animation: anim-naira-1 0.3s forwards ease-in;
     animation: anim-naira-1 0.3s forwards ease-in;
 }
 
 .button--naira-up:hover::before {
     -webkit-animation: anim-naira-2 0.3s forwards ease-in;
     animation: anim-naira-2 0.3s forwards ease-in;
 }
 
 @-webkit-keyframes anim-naira-1 {
     50% {
         -webkit-transform: translate3d(0, -50%, 0) rotate3d(0, 0, 1, -10deg);
         transform: translate3d(0, -50%, 0) rotate3d(0, 0, 1, -10deg);
         -webkit-animation-timing-function: ease-out;
         animation-timing-function: ease-out;
     }
     100% {
         -webkit-transform: translate3d(0, 0%, 0) rotate3d(0, 0, 1, 0deg);
         transform: translate3d(0, 0%, 0) rotate3d(0, 0, 1, 0deg);
     }
 }
 
 @keyframes anim-naira-1 {
     50% {
         -webkit-transform: translate3d(0, -50%, 0) rotate3d(0, 0, 1, -10deg);
         transform: translate3d(0, -50%, 0) rotate3d(0, 0, 1, -10deg);
         -webkit-animation-timing-function: ease-out;
         animation-timing-function: ease-out;
     }
     100% {
         -webkit-transform: translate3d(0, 0%, 0) rotate3d(0, 0, 1, 0deg);
         transform: translate3d(0, 0%, 0) rotate3d(0, 0, 1, 0deg);
     }
 }
 
 @-webkit-keyframes anim-naira-2 {
     50% {
         -webkit-transform: translate3d(0, 50%, 0) rotate3d(0, 0, 1, 10deg);
         transform: translate3d(0, 50%, 0) rotate3d(0, 0, 1, 10deg);
         -webkit-animation-timing-function: ease-out;
         animation-timing-function: ease-out;
     }
     100% {
         -webkit-transform: translate3d(0, 0%, 0) rotate3d(0, 0, 1, 0deg);
         transform: translate3d(0, 0%, 0) rotate3d(0, 0, 1, 0deg);
     }
 }
 
 @keyframes anim-naira-2 {
     50% {
         -webkit-transform: translate3d(0, 50%, 0) rotate3d(0, 0, 1, 10deg);
         transform: translate3d(0, 50%, 0) rotate3d(0, 0, 1, 10deg);
         -webkit-animation-timing-function: ease-out;
         animation-timing-function: ease-out;
     }
     100% {
         -webkit-transform: translate3d(0, 0%, 0) rotate3d(0, 0, 1, 0deg);
         transform: translate3d(0, 0%, 0) rotate3d(0, 0, 1, 0deg);
     }
 }
 
 .buttondownload {
     display: inline-block;
     margin: 1em;
     padding: 0 40px;
     border: none;
     background: none;
     color: inherit;
     vertical-align: middle;
     position: relative;
     z-index: 1;
     -webkit-backface-visibility: hidden;
     -moz-osx-font-smoothing: grayscale;
     background-color: $primary-clr;
     border-radius: 14px;
     font-weight: bold;
     color: #fff;
     text-decoration: none;
 }
 
 .icon-download {
     img {
         max-width: 36px;
     }
 }
 
 .button--naira:hover {
     background-color: #37474f;
     -webkit-transition: background-color 0s 0.3s;
     transition: background-color 0s 0.3s;
 }
 
 .button--naira.button--inverted:hover {
     background-color: #7986cb;
 }
 
 .button--naira:hover .button__icon {
     -webkit-transform: translate3d(0, 0, 0);
     transform: translate3d(0, 0, 0);
 }
 
 .button--naira:hover>span {
     opacity: 0;
     -webkit-transform: translate3d(0, 100%, 0);
     transform: translate3d(0, 100%, 0);
 }
 
 .button--naira-up:hover>span {
     -webkit-transform: translate3d(0, -100%, 0);
     transform: translate3d(0, -100%, 0);
 }